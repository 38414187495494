import React, { useEffect } from 'react'

import {
  useUser,
  useMapi,
  PackageCard,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'

import {
  VariableContent,
  scrollTo,
  Brandy,
  Typography,
  Stack,
  Columns,
} from '@leshen/ui'

import _get from 'lodash.get'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = () => {
  const { userData, userLocation } = useUser()

  const mapiData = useMapi()
  const phoneNumber = _get(mapiData, 'rotatedNumber')

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans, setUserData } =
    useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const { packages, disclaimers, product } = await zipLogic(
          userLocation.zipCode
        )

        setUserData((previousUserData) => ({
          ...previousUserData,
          loading: false,
          frontierPackages: packages,
          packageDisclaimers: disclaimers,
          productGroup: product,
        }))
      })()
    }

    if (!userData && availablePlans) {
      return
    }

    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  useEffect(() => {
    if (availablePlans) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const frontierPackagesExist =
    availablePlans &&
    availablePlans?.frontierPackages?.packages &&
    availablePlans?.frontierPackages?.packages.length > 0

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h4">
                Searching packages in your area.
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {frontierPackagesExist && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Internet Plans for Your Area</Typography>
              <Typography>
                Choose the right package for your lifestyle with Frontier
                Internet.
              </Typography>
            </>
          }
        >
          <Stack spacing="xxl">
            <Columns style={{ marginBottom: 16 }}>
              {availablePlans.frontierPackages.packages.map((data) => (
                <PackageCard
                  label={data.label}
                  packageData={{
                    ...data.brandy,
                  }}
                  key={data?.brandy?.packageName}
                  content={
                    <LinkButton to={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </LinkButton>
                  }
                />
              ))}
            </Columns>

            {userData?.frontierPackages?.product === 'Fiber' ? (
              <Brandy
                text="Limited time offer for Frontier Internet subscribers who are first-time YouTube TV customers. Terms apply.^"
                variant="legal"
              />
            ) : (
              availablePlans?.frontierPackages?.disclaimers?.map(
                (data) =>
                  data?.text && <Brandy text={data?.text} variant="legal" />
              )
            )}
          </Stack>
        </VariableContent>
      )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.frontierPackages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  {`We're having trouble locating service options for your area.`}
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection
